/*
 * Used for basic info...
 * Enables ADD, DELETE of collection of elemenets
 * For initialization accepts:
 * @param container_class - css class of container
 * @param index - index of collection
 * @param names - strings used in twig as selector for collection ie 'affiliations'
 *
 */
if(!Dynamic_form2){
  var Dynamic_form2 = {
  initialize: function (container_class, index, names, params){
    this.container_class = container_class;
    this.index = index;
    this.names = names;
    this.params = $.extend({at_least_one: true}, params);

    this.initializeEvents();
    // if there are errors on form scroll to first
    if ($('p[class*="error"]').length > 0){
      scrollToElement($('p[class*="error"]'));
    }

    for (let i = 0; i < names.length; i++){
      const name = names[i];

      if (this.hasEmptyData(name) == true){
        $('.js-' + name + '-add').attr('disabled', 'disabled').addClass('button--disabled');
      }
    }
  },
  initializeEvents: function (){
    const _this = this;
    for (let i = 0; i < _this.names.length; i++){
      var name = _this.names[i];
      var isInstitution = 'affiliations' === name;

      $('.' + this.container_class).on('click', `.js-${ name }-add`, function (event){
        event.preventDefault();
        _this.index++;
        let prototype_elem = $(`#js-${ name }_prototype`).attr('data-prototype');
        addPrototypeElement($(`.js-${ name }-collection`), prototype_elem, _this.index);
        $(`.js-${ name }-add`).attr('disabled', 'disabled').addClass('button--disabled');
      });

      $('.' + this.container_class).on('click', `.js-${ name }-delete`, function (event){
        event.preventDefault();
        if ($(`.js-${ name }-wrapper`).length > 1 || _this.params.at_least_one == false){
          $(this).closest(`.js-${ name }-wrapper`).remove();
        } else if (isInstitution) {
          $(this).closest(`.js-${ name }-wrapper`).remove();
          $(`.js-${ name }-add`).click();
          $(`.js-${ name }-add`).attr('disabled', 'disabled').addClass('button--disabled');
        } else {
          $(this).closest(`.js-${ name }-wrapper`).find('input[type="text"]').val('');
          $(`.js-${ name }-add`).attr('disabled', 'disabled').addClass('button--disabled');
        }

        if (_this.hasEmptyData(name) == true){
          $(`.js-${ name }-add`).attr('disabled', 'disabled').addClass('button--disabled');
        } else {
          $(`.js-${ name }-add`).removeAttr('disabled', 'disabled').removeClass('button--disabled');
        }

        if (isInstitution) {
          deleteBtnHandler();
        }

      });

      if (!isInstitution) {
        $(`.js-${name}-collection`).on('input', 'input[type="text"]', function (event) {
          event.preventDefault();
          if ($(this).val() == '') {
            $(`.js-${name}-add`).attr('disabled', 'disabled').addClass('button--disabled');
          } else {
            $(`.js-${name}-add`).removeAttr('disabled', 'disabled').removeClass('button--disabled');
          }
        });
      }

      if (isInstitution) {
        deleteBtnHandler()
      }

      function deleteBtnHandler() {
        if ($(`.js-affiliations-wrapper`).length === 1) {

          if ($('.js-affiliations-wrapper input').val() === '') {

            $('.js-affiliations-wrapper').find('.js-affiliations-delete').hide();

            $(`.js-affiliations-wrapper`).on('keyup', 'input', function () {
              if ($(this).parent('.js-affiliations-wrapper').find('input').val() !== '') {
                $('.js-affiliations-wrapper').find('.js-affiliations-delete').show();
              } else {
                $('.js-affiliations-wrapper').find('.js-affiliations-delete').hide();
              }
            })
          }
        }
      }
    }
  },
  hasEmptyData: function (name){
    let hasEmpty = false;
    var isInstitution = 'affiliations' === name;
    var additionalCase = false;
    $.each($(`.js-${ name }-collection`).find('input[type="text"]'), function (_, elem){
      if (isInstitution) {
        if ($(elem).hasClass('department')) {
          return;
        }
        additionalCase = $(elem).parent().find('input[type="hidden"]').val() == '';
      }

      if ($(elem).val() == '' || additionalCase){
        hasEmpty = true;
      }
    });

    return hasEmpty;
  }
};
}
